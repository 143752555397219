import { Atom, Cmp, Ctrl } from ":mods";
import "./styles/$shortcuts.css";
import "./styles/_dev.css";
import "./styles/app.css";
import "./styles/form.css";
import "./styles/solid-select.css";

export function EntryClient() {
  return (
    <Ctrl.Context.Meta title="MOC">
      <Ctrl.Context.AppFlavor title="Museum Training">
        <Cmp.Alert.ToastGroup />
        <Cmp.Popup.Modal />
        <Cmp.Popup.ConfirmDialog />
        <Atom.Route.Router>
          <Ctrl.Routes.Microsite />
          <Ctrl.Routes.Auth />
          <Ctrl.Routes.Signup />
          <Ctrl.Routes.Admin />
          <Ctrl.Routes.Student />
          <Ctrl.Routes.Tutor />
        </Atom.Route.Router>
      </Ctrl.Context.AppFlavor>
    </Ctrl.Context.Meta>
  );
}
